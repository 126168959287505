.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 1;
  }
  
  .left {
    left: 10px;
  }
  
  .right {
    right: 10px;
  }
  
  .scroll-icon {
    width: 24px;
    height: 24px;
    color: #000; /* Customize the color as needed */
  }
  